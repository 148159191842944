<template>
  <div class="claim-reward-page">
    <h1>Please connect your Discord to BAP</h1>

    <div class="claim-reward-page-input">
      <label>Connect Wallet</label>
      <!-- <input v-model="buyerAddress" /> -->
      <span
        v-if="buyerAddress && signature"
        class="claim-reward-connect-wallet"
      >{{ buyerAddress }}</span>
      <a
        v-else
        class="claim-reward-connect-button"
        href="#"
        @click.prevent="onConnect"
      >Connect</a>
    </div>
    <div class="claim-reward-page-input">
      <label>Connect Discord</label>

      <a
        class="claim-reward-connect-button"
        href="#"
      >Connect Discord</a>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { useWeb3Store } from "@/store/web3";
import { shorten } from "../helpers/utils";
export default {
  props: {
    connect: Function,
    signMessage: Function,
  },
  data() {
    return {
      signature: "",
      message: "",
    };
  },
  computed: {
    buyerAddress() {
      return shorten(this.walletAddress, 4);
    },
    walletAddress() {
      return useWeb3Store().walletAddress;
    },
  },
  watch: {
    walletAddress() {
      this.signature = "";
    },
  },
  methods: {
    async onConnect() {
      await this.connect();
      this.message = Date.now().toString();
      this.signature = await this.signMessage(this.message);
    },
  },
};
</script>
